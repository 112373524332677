import withLocalStorage from './withLocalStorage'
import { Container } from 'unstated'

interface JourneyState {
  currentStashAmount: null | number
  futureSpendingAmount: null | number
  futureRateOfReturn: null | number
  futureSavingsRate: null | number
  futureWithdrawalRate: number
}

class JourneyContainer extends Container<JourneyState> {
  state: JourneyState = {
    // Current stash to calculate from (null if auto)
    currentStashAmount: null,
    // Future spending (null if auto)
    futureSpendingAmount: null,
    // 'null' if auto, otherwise a specific rate
    futureRateOfReturn: 0.07,
    // 'null' if auto, otherwise a specific rate
    futureSavingsRate: null,
    // The expected withdrawal rate (4% safe default)
    futureWithdrawalRate: 0.04,
  }

  setCurrentStashAmount = (currentStashAmount: number | null) => {
    this.setState({ currentStashAmount })
  }

  setFutureSpendingAmount = (futureSpendingAmount: number | null) => {
    this.setState({ futureSpendingAmount })
  }

  setFutureSavingsRate = (futureSavingsRate: number | null) => {
    this.setState({ futureSavingsRate })
  }

  setFutureRateOfReturn = (futureRateOfReturn: number | null) => {
    this.setState({ futureRateOfReturn })
  }
}

export default withLocalStorage(JourneyContainer, {
  name: 'journey',
  version: 1,
})
