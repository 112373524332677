import { format } from 'date-fns'
import download from 'downloadjs'
import { graphql, Link, StaticQuery } from 'gatsby'
import localforage from 'localforage'
import React from 'react'
import { Subscribe } from 'unstated'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListSubheader from '@material-ui/core/ListSubheader'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'

import TargetRateDialog from './TargetRateDialog'

import Layout from '../../components/layout'

import { RateFormula } from '../../constants'
import SavingsContainer from '../../containers/SavingsContainer'
import JourneyContainer from '../../containers/JourneyContainer'
import { formatPercentage } from '../../util'
import withRoot from '../../withRoot'

import { legacyParse, convertTokens } from "@date-fns/upgrade/v2";

const rateFormulaOptions = [
  { value: RateFormula.AFTER_TAX, text: 'After-tax (recommended)' },
  { value: RateFormula.AFTER_TAX_DEFERRED, text: 'After-tax w/future taxes' },
  { value: RateFormula.GROSS, text: 'Gross pay' },
  { value: RateFormula.TAKE_HOME, text: 'Take-home pay (not recommended)' },
]

const getFormulaIndex = formula => {
  const found = rateFormulaOptions.findIndex(opt => opt.value === formula)

  if (found >= 0) {
    return found
  }

  return 0
}

class SettingsMenu extends React.Component {
  state = {
    formulaAnchorEl: null,
    showComponentsEditor: false,
    showSavingsRateDialog: false,
  }

  render() {
    const {
      selectedRateFormula,
      targetRate,
    } = this.props
    const { formulaAnchorEl } = this.state
    const selectedFormulaIndex = getFormulaIndex(selectedRateFormula)

    return (
      <>
        <div css={{ backgroundColor: 'white', marginTop: '16px' }}>
          <List component="nav">
            <ListSubheader>Savings</ListSubheader>
            <ListItem
              button
              component={Link}
              aria-label="Default savings rate components"
              to="/settings/line-items"
            >
              <ListItemText primary="Default line items" />
            </ListItem>
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="savings-rate-menu"
              aria-label="Savings goal"
              onClick={this.handleSavingsRateClick}
            >
              <ListItemText
                primary="Savings goal"
                secondary={formatPercentage(targetRate)}
              />
            </ListItem>
            <ListItem
              button
              aria-haspopup="true"
              aria-controls="formula-components-menu"
              aria-label="Rate calculation"
              onClick={this.handleFormulaMenuClick}
            >
              <ListItemText
                primary="Rate calculation method"
                secondary={rateFormulaOptions[selectedFormulaIndex].text}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Hide exact numbers"
                secondary="Only display percentages in overview"
              />
              <ListItemSecondaryAction>
                <Switch
                  checked={this.props.hideExactSavings}
                  onChange={this.handleHideExactSavingsChange}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListSubheader>General</ListSubheader>
            {/* <ListItem
              button
              aria-haspopup="true"
              aria-controls="currency-menu"
              aria-label="Currency"
            >
              <ListItemText primary="Currency" secondary="USD ($)" />
            </ListItem> */}
            <ListItem button component={Link} to="/integrations/dropbox">
              <ListItemText primary="Dropbox sync" />
            </ListItem>
            <ListItem button onClick={this.handleExportData}>
              <ListItemText primary="Export app data" />
            </ListItem>
            <ListSubheader>About</ListSubheader>
            <ListItem
              button
              component="a"
              href="https://trello.com/b/ADUor2Oc/reach-fi-app-roadmap"
            >
              <ListItemText>Roadmap</ListItemText>
            </ListItem>
            <ListItem
              button
              component="a"
              href="https://twitter.com/reachfiapp"
            >
              <ListItemText>Tweet @reachfiapp</ListItemText>
            </ListItem>
            <ListItem button component={Link} to="/settings/credits">
              <ListItemText primary="Credits" />
            </ListItem>
            <ListItem button component={Link} to="/privacy">
              <ListItemText primary="Privacy Policy" />
            </ListItem>
            <ListItem button component={Link} to="/terms">
              <ListItemText primary="Terms of Service" />
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="caption">
                  &copy; {new Date().getFullYear()}{' '}
                  <a href="https://kamranicus.com">Kamranicus</a>.{' '}
                  {this.props.version}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>

          <Menu
            id="formula-menu"
            anchorEl={formulaAnchorEl}
            open={Boolean(formulaAnchorEl)}
            onClose={this.handleFormulaMenuClose}
          >
            {rateFormulaOptions.map((option, index) => (
              <MenuItem
                key={option.value}
                selected={index === selectedFormulaIndex}
                onClick={event => this.handleFormulaMenuItemClick(event, index)}
              >
                {option.text}
              </MenuItem>
            ))}
          </Menu>

          <TargetRateDialog
            open={this.state.showSavingsRateDialog}
            onClose={this.handleSavingsRateDialogClose}
            onSave={this.handleSavingsRateSave}
            value={targetRate}
          />
        </div>
      </>
    )
  }

  handleComponentsMenuClick = () => {
    this.setState({ showComponentsEditor: true })
  }

  handleComponentsEditorClose = () => {
    this.setState({ showComponentsEditor: false })
  }

  handleComponentsEditorSave = components => {
    this.props.setDefaultComponents(components)
    this.handleComponentsEditorClose()
  }

  handleSavingsRateClick = e => {
    this.setState({ showSavingsRateDialog: true })
  }

  handleSavingsRateSave = targetRate => {
    this.setState({ showSavingsRateDialog: false }, () => {
      this.props.setTargetRate(targetRate)
    })
  }

  handleSavingsRateDialogClose = e => {
    this.setState({
      showSavingsRateDialog: false,
    })
  }

  handleHideExactSavingsChange = e => {
    this.props.setHideExactSavings(!this.props.hideExactSavings)
  }

  handleFormulaMenuClick = e => {
    this.setState({ formulaAnchorEl: e.currentTarget })
  }

  handleFormulaMenuItemClick = (event, index) => {
    this.setState({ formulaAnchorEl: null }, () => {
      this.props.setRateFormula(rateFormulaOptions[index].value)
    })
  }

  handleFormulaMenuClose = () => {
    this.setState({ formulaAnchorEl: null })
  }

  handleExportData = () => {
    const exportData = { _exportedAt: new Date().toISOString() }
    localforage
      .iterate((value, key) => {
        Object.assign(exportData, { [key]: value })
      })
      .then(() => {
        return download(
          JSON.stringify(exportData, null, 2),
          'reachfi-app-export.json',
          'application/json'
        )
      })
  }
}
SettingsMenu.defaultProps = {
  version: '',
  defaultComponents: {},
  selectedRateFormula: RateFormula.AFTER_TAX,
  targetRate: 0.5,
  hideExactSavings: false,
  setRateFormula(formula) {},
  setDefaultComponents(components) {},
  setTargetRate(rate) {},
  setHideExactSavings(hide) {},
}

const SettingsPage = ({ location: { pathname } }) => (
  <StaticQuery
    query={graphql`
      query SiteVersionQuery {
        site {
          buildTime
          siteMetadata {
            version
          }
        }
      }
    `}
    render={data => (
      <Layout title="Settings" pathname={pathname}>
        <Subscribe to={[SavingsContainer]}>
          {(savingsData) => (
            <SettingsMenu
              version={`v${process.env.COMMIT_REF || 'local'}-${format(legacyParse(data.site.buildTime), convertTokens('YYYY.MM.DD'))}`}
              defaultComponents={savingsData.state.defaultComponents}
              targetRate={savingsData.state.targetRate}
              selectedRateFormula={savingsData.state.rateFormula}
              hideExactSavings={savingsData.state.hideExactSavings}
              setTargetRate={savingsData.setTargetRate}
              setRateFormula={savingsData.setRateFormula}
              setDefaultComponents={savingsData.setDefaultComponents}
              setHideExactSavings={savingsData.setHideExactSavings}
            />
          )}
        </Subscribe>
      </Layout>
    )}
  />
)

export default withRoot(SettingsPage)
