import React from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

function TargetRateDialog({ open, onClose, onSave, value }) {
  const [targetRate, setTargetRate] = React.useState((value * 100).toFixed(0))
  const [targetRateError, setTargetRateError] = React.useState(null)

  const handleSavingsRateChange = React.useCallback(e => {
    const targetValue = e.target.value
    const newTargetValue = parseInt(targetValue, 10)

    if (Number.isNaN(newTargetValue)) {
      setTargetRate(targetValue)
      setTargetRateError('Must be a percentage')
      return
    }

    if (newTargetValue < 0 || newTargetValue > 100) {
      setTargetRate(targetValue)
      setTargetRateError('Must be between 0% and 100%')
      return
    }

    setTargetRate(newTargetValue)
    setTargetRateError(null)
  })

  const handleConfirm = React.useCallback(e => {
    if (targetRateError) {
      return
    }

    const targetValue = parseInt(targetRate, 10)
    onSave(targetValue / 100)
  })

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change target savings rate</DialogTitle>
      <DialogContent>
        <TextField
          type="number"
          value={targetRate}
          onChange={handleSavingsRateChange}
          error={Boolean(targetRateError)}
          helperText={targetRateError ? targetRateError : undefined}
          InputProps={{
            style: { maxWidth: 75 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
TargetRateDialog.defaultProps = {
  open: false,
  value: 0,
  onSave() {
    return
  },
  onClose() {
    return
  },
}

export default TargetRateDialog
